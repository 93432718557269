import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Loadable from '@loadable/component'
import PageHeading from './PageHeading'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const myQuery = graphql`
  {
    allStrapiBrands {
      nodes {
        id
        images {
          url
        }
      }
    }
  }
`

const options = {
  loop: true,
  margin: 30,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1024: {
      items: 4,
    },
  },
}

const BrandArea = () => {
  const {
    allStrapiBrands: { nodes },
  } = useStaticQuery(myQuery)

  return (
    <>
      <PageHeading pageTitle="Powered By" />
      <div className="brand-area ptb-100">
        <div className="container">
          <OwlCarousel className="brand-slider owl-theme" {...options}>
            {nodes.map(brandslider => {
              return (
                <div className="brand-item" key={brandslider.id}>
                  <img src={brandslider.images.url} alt="Brand Logo" />
                </div>
              )
            })}
          </OwlCarousel>
        </div>
      </div>
    </>
  )
}

export default BrandArea
