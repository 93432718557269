import React from 'react'

const PageBanner = ({ pageTitle }) => {
  return (
    <div className='inner-banner-header'>
      <div className='container'>
        <div className='inner-title text-center'>
          <h3>{pageTitle}</h3>
        </div>
      </div>
    </div>
  )
}

export default PageBanner
